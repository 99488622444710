.footer {
  background-color: var(--primary-color);
  color: var(--on-primary-color);
  text-align: center;
  padding: 10px;
  /* position: fixed; */
  bottom: 0;
  /* width: 100%; */
  /* height: 10vh; */
}

.footer nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.footer nav ul li {
  margin: 0 10px;
}
