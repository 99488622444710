.body {
  padding: 20px;
  padding-top: 20vh;
  background-color: var(--primary-color);
  /* padding-bottom: 15vh; */
  flex-grow: 1;
}

.body section {
  margin-bottom: 40px;
  background-color: var(--primary-color);
}

.body section h2 {
  color: var(--on-primary-color);
}

.body section p {
  color: var(--on-primary-color);
}

.body section .activity p {
  color: var(--primary-color);
}

.email-link {
  color: var(--on-primary-color);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
