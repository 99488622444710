.header {
  background-color: var(--primary-color);
  padding: 20px;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box; /* Ensure padding and borders are included in the element's total width and height */
  z-index: 1000; /* Ensure the header stays on top of other content */
}

.header h1 {
  margin: 0;
  padding: 0 20px;
}

.header nav {
  display: flex;

  /* margin-left: auto; */
  margin-right: 10vw;
}

@media (max-width: 480px) {
  .header nav {
    margin-top: 5px;
    flex-grow: 1;
    justify-content: center;
    margin-right: 0;
  }
}

.header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.header nav ul li {
  margin: 0 10px;
}

.header nav ul li a {
  color: var(--on-primary-color);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.header nav ul li button:hover {
  text-decoration: underline;
}

.header-logo {
  max-width: 50px;
  min-height: 40px;
}
