.activities {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 20px;
  cursor: pointer;
}

.activity {
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  flex: 1 1 calc(30% - 20px);
  padding: 20px;
  transition: transform 0.2s;
  max-width: 400px;
  box-sizing: border-box;
  height: 300px;
}

.activity img {
  max-width: 100%;
  border-radius: 10px;
  max-height: 180px;
}

.activity h3 {
  color: #282c34;
  margin: 10px 0;
}

.activity p {
  text-align: left;
}

.activity-text {
  color: var(--primary-color);
}

.activity:hover {
  transform: translateY(-5px);
}

@media (max-width: 768px) {
  .activity {
    flex: 1 1 80%;
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .activity {
    flex: 1 1 90%;
    max-width: 90%;
  }
}

.activity-longText {
  padding: 5x 10px 5 10px;
  transition: width 2s 2s, height 2s 2s, opacity 2s;
  overflow-y: auto;
}
